// @flow

import * as React from 'react';
import styled from 'styled-components';
import { Link } from '@latitude/link';
import { APP_STORE, GUTTER, ALIGN_ITEMS } from '../../utils/constants';
import { Flex } from '../Box/Box';
import { getAppStore } from './AppStoreBadge.helpers';
import imgBadgeAppStore from '../../images/badge-app-store.svg';
import imgBadgeGooglePlay from '../../images/badge-google-play.svg';

type AppStoreBadgeProps = {
  /** Specify the `download from app store` link URL */
  url: string
};

type AppStoreBadgeContainerProps = {
  /** Set the badge alignment. [$Values<typeof ALIGN_ITEMS>] */
  alignItems?: $Values<typeof ALIGN_ITEMS>,
  /** The child elements of the component. */
  children: React.Node
};

const config = {
  [APP_STORE.APPLE]: {
    image: imgBadgeAppStore,
    tagline: 'App Store'
  },
  [APP_STORE.GOOGLE]: {
    image: imgBadgeGooglePlay,
    tagline: 'Google Play'
  }
};

export const AppStoreBadge = ({ url }: AppStoreBadgeProps) => {
  const store = getAppStore(url);

  return !store ? null : (
    <Link
      href={url}
      noStyle
      target="_blank"
      rel="noopener noreferrer"
      trackEventData={{
        label: config[store].tagline
      }}
    >
      <img src={config[store].image} alt={config[store].tagline} />
    </Link>
  );
};

export const AppStoreBadgeContainer = ({
  alignItems = ALIGN_ITEMS.FLEX_START,
  children
}: AppStoreBadgeContainerProps) => {
  const Container = styled(Flex)`
    flex-direction: row;
    align-items: ${({ alignItems }: AppStoreBadgeContainerProps) => alignItems};
    > a {
      flex: 1 1 128px;
      max-width: 128px;
      > img {
        width: 100%;
      }
    }
    > a:not(:last-child) {
      margin-right: 20px;
    }

    @media (min-width: 380px) {
      flex-direction: row;
      justify-content: ${({ alignItems }: AppStoreBadgeContainerProps) =>
        alignItems};
      > a:not(:last-child) {
        margin-right: ${GUTTER.G30};
      }
    }
    img {
      position: relative;
      z-index: auto;
      top: auto;
    }
  `;
  return <Container alignItems={alignItems}>{children}</Container>;
};
