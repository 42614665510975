// @flow

import { APP_STORE } from '../../utils/constants';

export const getAppStore = (url: string): ?string => {
  if (url.includes('apps.apple.com')) {
    return APP_STORE.APPLE;
  } else if (url.includes('play.google.com')) {
    return APP_STORE.GOOGLE;
  }
};
